import Dinero from "dinero.js"
import axios from "axios"
import createDispatcher from "./createDispatcher"

const dispatcher = createDispatcher("klaviyo")

export const addedToCart = ({
  productVariantId,
  quantity,
  cartResponse,
  currentStore
}) => {
  //console.log(cartResponse , "cartResponse")
  try {
    const _learnq = dispatcher(window._learnq)
    const addedProduct = cartResponse.cartItems.find(
      (p) => p.id === productVariantId
    )
    if (addedProduct.type !== "productVariant") return

    //console.log("addedProduct", addedProduct)
    const klavioCart = {
      $value: toDinero(
        cartResponse.totalPriceWithDiscount,
        currentStore.currencyUnit
      ).toUnit(),
      AddedItemProductName: addedProduct.attribute.displayName?.en,
      AddedItemProductID: addedProduct.id,
      AddedItemImageURL: addedProduct.imageUrl,
      AddedItemPrice: toDinero(
        addedProduct.price[currentStore.currencyUnit],
        currentStore.currencyUnit
      ).toUnit(),
      AddedItemQuantity: quantity,
      ProductURL: addedProduct.parentSlug,
      CountryCode: currentStore.countryCode,
      ItemNames: cartResponse.cartItems.map((ct) => ct.attribute.displayName?.en),
      CheckoutURL: `${window.location.origin}/checkout`,
      Items: toKlaviyoItems(cartResponse.cartItems, currentStore.currencyUnit)
    }

    const resp = _learnq.push(["track", "Added to Cart", klavioCart])
    console.debug("Set klaviyo added to cart response: ", resp)
  } catch (error) {
    console.error(error)
  }
  //console.log(addedToCart , "addedToCart")
}

export const identify = ({ address }) => {
  try {
    const _learnq = dispatcher(window._learnq)
    const resp = _learnq.push([
      "identify",
      {
        $email: address.email,
        $first_name: address.firstName,
        $last_name: address.lastName
      }
    ])
    console.debug("Set klaviyo identity response: ", resp)
  } catch (error) {
    console.error(error)
  }
}

export const startedCheckout = ({
  storedCart,
  currentStore,
  brinkSessionId
}) => {
  try {
    const _learnq = dispatcher(window._learnq)
    const cartProuductVariants = storedCart?.cartItems?.filter(
      (i) => i.type === "productVariant"
    )
    const resp = _learnq.push([
      "track",
      "Started Checkout",
      {
        $event_id: `${brinkSessionId}_${new Date().valueOf()}`,
        $value: toDinero(
          storedCart.totalPriceWithDiscount,
          currentStore.currencyUnit
        ).toUnit(),
        CountryCode: currentStore.countryCode,
        ItemNames: cartProuductVariants.map((ct) => ct.attribute.displayName?.en),
        CheckoutURL: `${window.location.origin}/checkout/?cart=${brinkSessionId}`,
        Items: toKlaviyoItems(cartProuductVariants, currentStore.currencyUnit)
      }
    ])
    console.debug("Set klaviyo started checkout response: ", resp)
  } catch (error) {
    console.error(error)
  }
  //console.log(startedCheckout , "startedCheckout")
}

const orderedProducts = (
  billingAddress,
  orderId,
  cartItems,
  currencyUnit,
  _learnq
) => {
  try {
    const orderedProductEvents = cartItems
      .filter((p) => p.type === "productVariant")
      .map((item) => [
        "track",
        "Ordered Product",
        {
          customer_properties: {
            $email: billingAddress.email,
            $first_name: billingAddress.firstName,
            $last_name: billingAddress.lastName,
            $country: billingAddress.country
          },
          properties: {
            $event_id: orderId,
            OrderId: orderId,
            $value: toDinero(item.price[currencyUnit], currencyUnit).toUnit(),
            ProductID: item.id,
            ProductName: item.attribute.displayName?.en,
            Quantity: item.quantity,
            ImageURL: item.imageUrl,
            Categories: [item.category],
            ProductURL: `${window.location.origin}/product/${item.slug}`
          }
        }
      ])
    const result = orderedProductEvents.map((orderedProductEvent) =>
      _learnq.push(orderedProductEvent)
    )
    console.debug("Set klaviyo orderedProducts response: ", result)
  } catch (error) {
    console.error(error)
  }
}

export const placedOrder = ({order, discountCode, currentStore, email, cart}) => {

  try {
    const _learnq = dispatcher(window._learnq)
    const billingAddress = order.billingAddress
    const event = [
      "track",
      "Placed Order",
      {
        customer_properties: {
          $email: email,
          $first_name: order?.billingAddress.givenName,
          $last_name: order?.billingAddress.familyName,
          $phone_number: order?.billingAddress.phone,
          $address1: order?.billingAddress.address,
          $address2: order?.billingAddress.houseNumberOrName || "",
          $city: order?.billingAddress.city,
          $zip: order?.billingAddress.postalCode,
          $region: "",
          $country: order?.billingAddress.country
        },
        properties: {
          $event_id: order.id,
          $value: toDinero(
            order.orderAmountWithDiscount,
            currentStore.currencyUnit
          ).toUnit(),
          CountryCode: currentStore.countryCode,
          DiscountCode: discountCode || "",
          DiscountValue: toDinero(order.orderAmount, currentStore.currencyUnit)
            .subtract(
              toDinero(order.orderAmountWithDiscount, currentStore.currencyUnit)
            )
            .toUnit(),
          ItemNames: cart.cartItems.map((ct) => ct.attribute.displayName?.en),
          Items: toKlaviyoItems(cart.cartItems, currentStore.currencyUnit)
        },
        BillingAddress: toKlavioAddress(billingAddress),
        ShippingAddress: toKlavioAddress(order.shippingAddress)
      }
    ]
    const resp = _learnq.push(event)
    orderedProducts(
      billingAddress,
      order.id,
      cart.cartItems,
      currentStore.currencyUnit,
      _learnq
    )
    console.debug("Set klaviyo placedOrder response: ", resp)
  } catch (error) {
    console.error(error)
  }
}

export const viewProduct = ({ sanityProduct, languageCode }) => {
  try {
    const _learnq = dispatcher(window._learnq)
    const resp = _learnq.push([
      "track",
      "Viewed Product",
      {
        ProductName: sanityProduct.displayName.en,
        ProductID: sanityProduct._id,
        LanguageCode: languageCode
      }
    ])
    console.debug("Set klaviyo Viewed Product response: ", resp)
  } catch (error) {
    console.error(error)
  }
}

const toKlavioAddress = (address) => {
  return {
    email: address.email,
    first_name: address.firstName,
    last_name: address.lastName,
    phone_number: address.phone,
    address1: address.address,
    address2: address.houseNumberOrName || "",
    city: address.city,
    zip: address.postalCode,
    region: "",
    country: address.country
  }
}

const toKlaviyoItems = (items, currencyUnit) => {
  return items.map((item) => ({
    ProductID: item.id,
    ProductName: item.attribute.displayName?.en,
    Quantity: item.quantity,
    ItemPrice: toDinero(item.price[currencyUnit], currencyUnit).toUnit(),
    RowTotal: toDinero(item.price[currencyUnit], currencyUnit)
      .multiply(item.quantity)
      .toUnit(),
    ImageURL: item.imageUrl,
    ProductURL: `${window.location.origin}/product/${item.slug}`
  }))
}

const toDinero = (amount, currencyUnit) => {
  return new Dinero({ amount: amount, currency: currencyUnit })
}

export const getKlavyioApiKey = (currencyUnit) => {
  try {
    const env = process.env.GATSBY_KLAVIYO_ENVIRONMENT || "test"
    const klavioyApiKeys = {
      test: {
        default: "Rpii3c"
      },
      production: {
        default: "RNjvcm"
      }
    }
    const key = klavioyApiKeys[env][currencyUnit] || klavioyApiKeys[env].default
    if (!key) console.error("Missing Klaviyo Apikey")
    return key
  } catch (error) {
    console.error("Missing Klaviyo Apikey")
    console.error(error)
  }
}

export const getKlavyioListIds = (currencyUnit) => {
  try {
    const env = process.env.GATSBY_KLAVIYO_ENVIRONMENT || "test"
    const klavioyListIds = {
      test: {
        default: "TXbeMh"
      },
      production: {
        default: "TFXT6A"
      }
    }
    const key = klavioyListIds[env][currencyUnit] || klavioyListIds[env].default
    if (!key) console.error("Missing Klaviyo List ID")
    return key
  } catch (error) {
    console.error("Missing Klaviyo List ID")
    console.error(error)
  }
}

export const subscribeToNewsletter = async (
  currencyUnit,
  email,
  klaviyoListId
) => {
  const config = Object.assign(
    {},
    {
      g: klaviyoListId || getKlavyioListIds(currencyUnit),
      email: email
    }
  )

  const body = Object.keys(config).reduce((str, key) => {
    str.append(key, config[key])
    return str
  }, new URLSearchParams())

  return (
    await axios({
      url: "https://manage.kmail-lists.com/ajax/subscriptions/subscribe",
      method: "POST",
      headers: {
        "Access-Control-Allow-Headers": "*",
        "Content-Type": "application/x-www-form-urlencoded; charset=utf-8"
      },
      data: body
    })
  ).data
}

export const subscribeToOutOfStock = async (email, variantId, productId) => {
  const config = Object.assign(
    {},
    {
      a: getKlavyioApiKey(),
      email: email,
      variant: variantId,
      product: productId,
      platform: "api",
      g: "U8K8jh"
    }
  )

  const body = Object.keys(config).reduce((str, key) => {
    str.append(key, config[key])
    return str
  }, new URLSearchParams())

  return (
    await axios({
      url: "https://a.klaviyo.com/onsite/components/back-in-stock/subscribe",
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=utf-8"
      },
      data: body
    })
  ).data
}
