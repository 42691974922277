import * as google from "./google"
import * as klaviyo from "./klaviyoEvents"
//import * as facebook from "./facebookPixel"

const dispatchEvent = (props) => (event) => {
  try {
    event(props)
  } catch (error) {
    console.error("events:", error)
  }
}

const addToCartEvents = [
  google.addToCart,
  klaviyo.addedToCart
  //facebook.addToCart
]

export const addToCart = ({
  cartResponse,
  productVariantId,
  quantity,
  currentStore,
  category,
  listName,
  name
}) =>
  addToCartEvents.forEach(
    dispatchEvent({
      cartResponse,
      productVariantId,
      name,
      quantity,
      currentStore,
      category,
      listName
    })
  )

const removeFromCartEvents = [google.removeFromCart]

export const removeFromCart = ({
  productVariantId,
  name,
  quantity,
  category,
  price,
  discount,
  currentStore
}) =>
  removeFromCartEvents.forEach(
    dispatchEvent({
      productVariantId,
      name,
      quantity,
      price,
      discount,
      category,
      currentStore
    })
  )

const purchaseEvents = [
  google.purchase,
  klaviyo.placedOrder
  //facebook.purchase
]

export const purchase = ({ order, discountCode, cart, currentStore, email }) =>
  purchaseEvents.forEach(
    dispatchEvent({
      order,
      discountCode,
      cart,
      email,
      currentStore
    })
  )

const initiateCheckoutEvents = [
  google.initiateCheckout,
  klaviyo.startedCheckout
  //facebook.initiateCheckout
]

export const initiateCheckout = (storedCart, currentStore, brinkSessionId) =>
  initiateCheckoutEvents.forEach(
    dispatchEvent({
      storedCart,
      currentStore,
      brinkSessionId
    })
  )

const viewProductEvents = [
  google.viewProduct,
  klaviyo.viewProduct
  //facebook.viewContent
]

export const viewProduct = ({
  sanityProductVariant,
  sanityProduct,
  languageCode,
  prices,
  currentStore,
  index,
  discount,
  listName
}) =>
  viewProductEvents.forEach(
    dispatchEvent({
      sanityProductVariant,
      sanityProduct,
      languageCode,
      prices,
      index,
      discount,
      currentStore,
      listName
    })
  )

const viewCartEvents = [google.viewCart]

export const viewCart = (cart) =>
  viewCartEvents.forEach(
    dispatchEvent({
      cart
    })
  )

const addShippingEvents = [google.addShipping]

export const addShipping = (cart, shippingOption, discountCode) =>
  addShippingEvents.forEach(
    dispatchEvent({
      cart,
      shippingOption,
      discountCode
    })
  )

const viewCollectionsEvents = [google.viewCollection]

export const viewCollection = (products, currentStore, listName) =>
  viewCollectionsEvents.forEach(
    dispatchEvent({
      products,
      currentStore,
      listName
    })
  )

const clickProductEvents = [google.clickProduct]

export const clickProduct = ({ product, positionInList, currentStore }) =>
  clickProductEvents.forEach(
    dispatchEvent({
      product,
      positionInList,
      currentStore
    })
  )

const identifyEvents = [klaviyo.identify]

export const identify = (address) =>
  identifyEvents.forEach(
    dispatchEvent({
      address
    })
  )

const signUpEvents = [google.subscribeToNewsletter]

export const signup = ({ email, pageLocation }) => {
  signUpEvents.forEach(
    dispatchEvent({
      email,
      pageLocation
    })
  )
}

const addPaymentInfoEvents = [google.addPaymentInfo]

export const addPaymentInfo = (cart, paymentMethod, discountCode) =>
  addPaymentInfoEvents.forEach(
    dispatchEvent({
      cart,
      paymentMethod,
      discountCode
    })
  )
